<template>
  <section class="indicator-chart-container">
    <header class="pl-8">
      <h2 class="text-sm uppercase md:font-medium tracking-wide">{{ indicatorsHeadline }}</h2>
      <nav v-if="hasCategories" class="text-right">
        <button
          class="font-semibold text-sm mr-4 md:mr-0 md:py-1 md:px-3 md:rounded-full"
          :class="{ active: dataToken == 0 }"
          @click="dataToken = 0"
        >
          Details
        </button>
        <button
          class="font-semibold text-sm mr-4 md:mr-0 md:py-1 md:px-3 md:rounded-full"
          :class="{ active: dataToken == 1 }"
          @click="dataToken = 1"
        >
          Categories
        </button>
      </nav>
    </header>
    <svg class="indicator-chart"></svg>
  </section>
</template>

<script>
/* eslint no-unused-vars: 'off' */
import * as d3 from 'd3'
import { kpiToText } from '@/utils/text'
import { colors } from '@/utils/config.js'
import { mapState, mapGetters } from 'vuex'

const canvas = {
  width: 350,
  margin: {
    top: 20,
    right: 10,
    bottom: 10,
    left: 180
  }
}

export default {
  name: 'IndicatorChart',
  data() {
    return {
      dataToken: 0
    }
  },
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['schema']),
    ...mapGetters(['year', 'kpis', 'categories']),
    indicatorsHeadline() {
      return this.$store.getters.text('indicators')
    },
    chartData() {
      return this.createChartData(this.details)
    },
    hasCategories() {
      return this.chartData[1].length > 0
    }
  },
  watch: {
    details(newVal) {
      if (newVal) {
        this.dataToken = 0
        this.initChart()
        // const chartData = this.createChartData(newVal)
        // const svg = d3.select('.indicator-chart')
        // this.renderChart(svg, chartData[1])
      }
    },
    dataToken() {
      this.initChart()
    }
  },

  methods: {
    initChart() {
      d3.select('.indicator-chart').remove()

      const svg = d3
        .select('.indicator-chart-container')
        .append('svg')
        .classed('indicator-chart', true)

      this.renderChart(svg, this.chartData[this.dataToken])
    },
    renderChart(svg, data) {
      const height = data.length * 35 // dynamic chart height

      if (!svg.g) {
        svg.g = svg.append('g')
        svg.bars = svg.g.append('g').classed('bars', true)
        svg.labels = svg.g.append('g').classed('labels', true)
        svg.xAxis = svg.g.append('g')
        svg.yAxis = svg.g.append('g')
      }

      svg.attr('viewBox', `0 0 ${canvas.width} ${height}`)

      const x = d3
        .scaleLinear()
        .domain([0, 100]) // d3.max(data, d => d.value)
        .range([canvas.margin.left, canvas.width - canvas.margin.right])

      const y = d3
        .scaleBand()
        .domain(d3.range(data.length))
        .range([canvas.margin.top, height - canvas.margin.bottom])
        .padding(0.1)

      const color = d3.interpolate(colors.base, colors.accent)

      const xAxis = g =>
        g
          .attr('transform', `translate(0,${canvas.margin.top})`)
          .call(
            d3
              .axisTop(x)
              .ticks(canvas.width / 80)
              .tickSizeInner((height - canvas.margin.top - canvas.margin.bottom) * -1)
          )
          .call(g => g.select('.domain').remove())

      const yAxis = g =>
        g
          .attr('transform', `translate(${canvas.margin.left},0)`)
          .call(
            d3
              .axisLeft(y)
              .tickFormat(i => data[i].name)
              .tickSizeOuter(0)
              .tickSizeInner(0)
          )
          .call(g => g.select('.domain').remove())

      const format = x.tickFormat(20)

      svg.bars
        .selectAll('rect')
        .data(data)
        .join('rect')
        .attr('fill', d => color(d.value / 100))
        .attr('x', x(0))
        .attr('y', (d, i) => y(i))
        .attr('width', d => x(d.value) - x(0))
        .attr('height', y.bandwidth())

      svg.labels
        .attr('text-anchor', 'end')
        .style('font', '12px sans-serif')
        .selectAll('text')
        .data(data)
        .join('text')
        .attr('fill', d => (d.value > 5 ? 'white' : 'black'))
        .attr('x', d => (d.value > 5 ? x(d.value) - 4 : canvas.margin.left + 12 + d.value))
        .attr('y', (d, i) => y(i) + y.bandwidth() / 2)
        .attr('dy', '0.35em')
        .text(d => (d.value === 0 ? '✓' : format(d.value)))

      svg.xAxis.call(xAxis)
      svg.yAxis.call(yAxis)

      svg.xAxis
        .selectAll('line')
        .style('opacity', 0.25)
        .attr('stroke', '#ddd')
    },

    createChartData(data) {
      return [
        this.kpis.map(kpi => {
          if (data.hasOwnProperty(kpi)) {
            return {
              name: kpiToText(kpi),
              value: data[kpi]
            }
          }
        }),
        this.categories
          .map(kpi => {
            if (data.hasOwnProperty(kpi)) {
              return {
                name: kpiToText(kpi),
                value: data[kpi]
              }
            }
          })
          .filter(d => d !== undefined) // filter off the undefineds
      ]
    }
  },
  mounted() {
    this.initChart()
  }
}
</script>

<style>
/* @todo Duplicate from YearNav.vue. Fix */
.indicator-chart-container .active {
  border-bottom: 2px solid theme(colors.light.base);
}

@screen md {
  .indicator-chart-container .active {
    color: theme(colors.white) !important;
    background-color: theme(colors.light.base);
    border-bottom: 0;
  }
}
</style>
