<template>
  <div>
    <!-- The KSI bar chart -->
    <indicator-chart v-if="details" :details="details[year]" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import IndicatorChart from '@/components/IndicatorChart'

export default {
  components: {
    IndicatorChart
  },
  computed: {
    ...mapGetters(['country', 'countryDetails', 'year']),
    details() {
      return this.countryDetails(this.country)
    }
  }
}
</script>
